<template>
  <div />
</template>

<script>
import UserInfoMixin from "@/views/auth/UserInfoMixin";

export default {
  mixins: [UserInfoMixin],
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  watch: {
    userInfo() {
      this.userInfoUpdate();
    }
  },
  methods: {
    userInfoUpdate() {}
  }
};
</script>

<style>
</style>