var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-dropdown",
        { attrs: { "aria-role": "list" } },
        [
          _c(
            "button",
            {
              staticClass: "button is-text",
              attrs: { slot: "trigger" },
              slot: "trigger"
            },
            [
              _c("b-icon", {
                staticClass: "has-text-grey",
                attrs: { icon: "dots-vertical" }
              })
            ],
            1
          ),
          _vm.disableSharing == false
            ? _c(
                "b-dropdown-item",
                {
                  attrs: { "aria-role": "listitem" },
                  on: {
                    click: function($event) {
                      return _vm.showDocumentShareDialog(_vm.document)
                    }
                  }
                },
                [
                  _c("b-icon", {
                    staticClass: "has-text-grey",
                    attrs: { icon: "share" }
                  }),
                  _vm._v(" Get Signature ")
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-dropdown-item",
            {
              attrs: { "aria-role": "listitem" },
              on: {
                click: function($event) {
                  return _vm.downloadDocument(_vm.document)
                }
              }
            },
            [
              _c("b-icon", {
                staticClass: "has-text-grey",
                attrs: { icon: "download" }
              }),
              _vm._v(" Download ")
            ],
            1
          ),
          _c(
            "b-dropdown-item",
            {
              attrs: { "aria-role": "listitem" },
              on: {
                click: function($event) {
                  return _vm.deleteDocument(_vm.document)
                }
              }
            },
            [
              _c("b-icon", {
                staticClass: "has-text-grey",
                attrs: { icon: "delete" }
              }),
              _vm._v(" Delete ")
            ],
            1
          )
        ],
        1
      ),
      _c("share-dialog", {
        attrs: {
          visibility: _vm.isShareDialogShown,
          "document-id": _vm.document.id
        },
        on: {
          handleDocumentShareCloseDialog: _vm.handleDocumentShareCloseDialog
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }