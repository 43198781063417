<template>
  <div>
    <b-dropdown aria-role="list">
      <button
        slot="trigger"
        class="button is-text"
      >
        <b-icon
          icon="dots-vertical"
          class="has-text-grey"
        />
      </button>
      <b-dropdown-item
        v-if="disableSharing == false"
        aria-role="listitem"
        @click="showDocumentShareDialog(document)"
      >
        <b-icon
          icon="share"
          class="has-text-grey"
        /> Get Signature
      </b-dropdown-item>
      <b-dropdown-item
        aria-role="listitem"
        @click="downloadDocument(document)"
      >
        <b-icon
          icon="download"
          class="has-text-grey"
        /> Download
      </b-dropdown-item>
      <b-dropdown-item
        aria-role="listitem"
        @click="deleteDocument(document)"
      >
        <b-icon
          icon="delete"
          class="has-text-grey"
        /> Delete
      </b-dropdown-item>
    </b-dropdown>
    <share-dialog
      :visibility="isShareDialogShown"
      :document-id="document.id"
      @handleDocumentShareCloseDialog="handleDocumentShareCloseDialog"
    />
  </div>
</template>

<script>
import axios from "axios";

import ShareDialog from './ShareDialog'
import { eventHub } from "@/commons/event-bus.js";

export default {
  components : {
    ShareDialog
  },
  props: {
    document: {
      type: Object,
      required: true
    },
    disableSharing: {
      type:Boolean,
      default: false
    }
	},
  data() {
    return {
      isLoading: true,
      isShareDialogShown: false,
    };
  },
  watch: {
    document(value) {
      this.document = value;
    }
  },
  methods: {
    handleDocumentShareCloseDialog(){
      this.isShareDialogShown = false;
    },
    showDocumentShareDialog(document ) { 
      this.isShareDialogShown = true;
    },
    deleteDocument(document) {
      this.$buefy.dialog.confirm({
        title: "Delete file ",
        message:
          "Are you sure you want to delete <b>" +
          document.name +
          "</b>? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteRequest(document)
      });
    },
    deleteRequest(document) {
      axios
        .delete(
          '/organizations/' +
            this.$store.state.user.organization.id +
            '/documents/' +
            document.id
        )
        .then((response) => {
          if (response.status == 204) {
            eventHub.$emit("documents.list.refresh");
            this.$buefy.toast.open({
              message: document.name + ' deleted!',
              type: 'is-success',
            });
            this.getDocuments();
          }
        })
        .catch((e) => {
          if (e.response.status == 400) {
            eventHub.$emit("documents.list.refresh");
            this.$alert('Please try again later.', 'Oops', {
              confirmButtonText: 'OK',
            });
          }
        });
    },
    downloadDocument(doc){
      const url = process.env.VUE_APP_BASE_URL +"/users/files/download?file="+ doc.url;
      axios({
        method: 'get',
        url: url,
        responseType: 'arraybuffer',
      }).then(function(response) {
        let blob = new Blob([response.data], { type: 'application/pdf' })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = doc.name;
        link.click()
        URL.revokeObjectURL(link.href);
      })
    }
  }

}
</script>

<style>

</style>