var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      staticClass: "document-dialog",
      style: { width: _vm.$responsive.width + "px" },
      attrs: { active: _vm.dialog, scroll: "keep", "can-cancel": false },
      on: {
        "update:active": function($event) {
          _vm.dialog = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: " dialog modal-card", staticStyle: { width: "auto" } },
        [
          _c("header", { staticClass: "modal-card-head" }, [
            _c("p", { staticClass: "modal-card-title" }, [_vm._v(" Share ")])
          ]),
          _c(
            "section",
            { staticClass: "modal-card-body" },
            [
              _c(
                "b-field",
                { attrs: { label: "Email Address(s)" } },
                [
                  _c("b-taginput", {
                    attrs: {
                      icon: "email",
                      size: "is-medium",
                      "before-adding": _vm.validateEmailAddress
                    },
                    model: {
                      value: _vm.emails,
                      callback: function($$v) {
                        _vm.emails = $$v
                      },
                      expression: "emails"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-field",
                { attrs: { label: "Message" } },
                [
                  _c("b-input", {
                    attrs: { maxlength: "200", type: "textarea" },
                    model: {
                      value: _vm.message,
                      callback: function($$v) {
                        _vm.message = $$v
                      },
                      expression: "message"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("footer", { staticClass: "modal-card-foot" }, [
            _c("div", { staticClass: "level action-buttons" }, [
              _c(
                "div",
                { staticClass: "level-left" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "button",
                      attrs: { type: "button", rounded: "" },
                      on: {
                        click: function($event) {
                          return _vm.handleClose()
                        }
                      }
                    },
                    [_vm._v(" Close ")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "level-right" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "button is-primary",
                      attrs: {
                        loading: _vm.isSavingProgress,
                        "icon-left": "share",
                        rounded: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.share()
                        }
                      }
                    },
                    [_vm._v(" Share ")]
                  )
                ],
                1
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }