<template>
  <b-modal
    :active.sync="dialog"
    scroll="keep"
    :can-cancel="false"
    class="document-dialog"
    :style="{ width: $responsive.width + 'px' }"
  >
    <div
      class=" dialog modal-card"
      style="width: auto"
    >
      <header class="modal-card-head">
        <p class="modal-card-title">
          Share
        </p>
      </header>
      <section class="modal-card-body">
        <b-field label="Email Address(s)">
          <b-taginput
            v-model="emails"
            icon="email"
            size="is-medium"
            :before-adding="validateEmailAddress"
          />
        </b-field>
        <b-field label="Message">
          <b-input
            v-model="message"
            maxlength="200"
            type="textarea"
          />
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <div class="level action-buttons">
          <div class="level-left">
            <b-button
              class="button"
              type="button"
              rounded
              @click="handleClose()"
            >
              Close
            </b-button>
          </div>
          <div class="level-right">
            <b-button
              class="button is-primary"
              :loading="isSavingProgress"
              icon-left="share"
              rounded
              @click="share()"
            >
              Share
            </b-button>
          </div>
        </div>
      </footer>
    </div>
  </b-modal>
</template>
<script>
import isValidEmail from 'is-valid-email';
import axios from 'axios';

export default {
  props: {
    visibility: {
      default: false,
      type: Boolean,
    },
    documentId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      isSavingProgress: false,
      isDeletingProgress: false,
      selectedModuleIdToMove: -1,
      emails: [],
      message: null
    };
  },
  watch: {
    visibility(value) {
      this.dialog = value;
    },
  },
  methods: {
    handleClose() {
      this.dialog = false;
      this.$emit('handleDocumentShareCloseDialog', this.dialog, null);
    },
    share() {
      this.isLoading = true;
      let url =
        '/organizations/' +
        this.$store.state.user.organization.id +
        '/documents/' +
        this.documentId +
        '/share';
      let data = { mail_addresses: this.emails, message: this.message };
      axios
        .post(url, data)
        .then((response) => {
          this.isLoading = false;
          this.$buefy.toast.open('Document shared ');
          this.$emit('handleDocumentShareCloseDialog', this.dialog, null);
        })
        .catch((e) => {
          this.isLoading = false;
          this.$buefy.toast.open({
            type: 'is-danger',
            message: 'Cannot share document. Please try again later.',
          });
        });
    },
    validateEmailAddress(valueToCheck) {
      if (!isValidEmail(valueToCheck)) {
        this.$buefy.toast.open({
          type: 'is-danger',
          message: 'Email address is not valid',
        });
        return false;
      }
      return true;
    },
  },
};
</script>

<style>
.document-dialog {
  z-index: 1;
}

.modal {
  z-index: 100;
}
.document-dialog > .modal-background {
  background-color: transparent;
}
.modal-content {
  box-shadow: 0px 0px 16px 12px rgba(119, 119, 119, 0.3);
  -moz-box-shadow: 0px 0px 16px 12px rgba(119, 119, 119, 0.3);
  -webkit-box-shadow: 0px 0px 16px 12px rgba(119, 119, 119, 0.3);
}

.modal-card-head,
.modal-card-foot {
  background-color: #ffffff !important;
}
.modal-card-foot {
  padding: 10px;
}

.modal-card-foot {
  border-top: none;
}

.action-buttons {
  width: 100%;
}
.signature-pad {
  border: 1px solid #cccccc;
}
/* .modal-background {
  margin: 0;
  height: 100%;
  overflow: hidden;
  height: 100%;
  overflow-y: scroll;
} */
</style>
