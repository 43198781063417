import axios from 'axios'

const UserInfoMixin = {
  computed: {
    userInfo() {
      return this.$store.state.user
    }
  },
  watch: {
    $route: 'getUserInfo',
    userInfo() {
      if (this.$store.state.user == null) {
        this.getUserInfo()
      }
    }
  },
  mounted() { },
  methods: {
    getUserInfo() {
      if (this.userInfo != null) {
        return
      }
      axios
        .get('/users/me')
        .then(response => {
          this.$store.commit('setUser', response.data)
          this.$store.commit("setCurrentModule", "customers")

        })
        // eslint-disable-next-line no-unused-vars
        .catch(e => {
          if (e.response.status = 401) {
            this.$store.commit('setUser', null)
            window.$cookies.set("token", "")
            window.location.href = "/"
            router.push({ path: "/" })
          }
        })
    },
  }
}
export default UserInfoMixin
