var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("b-loading", {
        attrs: {
          "is-full-page": false,
          active: _vm.isLoading,
          "can-cancel": false
        },
        on: {
          "update:active": function($event) {
            _vm.isLoading = $event
          }
        }
      }),
      _vm.documents.length == 0 && !_vm.isLoading && _vm.searchText == ""
        ? _c("div", [
            _c("section", { staticClass: "hero" }, [
              _c("div", { staticClass: "hero-body" }, [
                _vm._m(0),
                _c("div", { staticClass: "columns is-centered " }, [
                  _c("div", { staticClass: "column  is-half" }, [
                    _c("p"),
                    _c(
                      "p",
                      [
                        _c(
                          "b-field",
                          [
                            _c(
                              "b-upload",
                              {
                                attrs: {
                                  "drag-drop": "",
                                  expanded: "",
                                  accept: "application/pdf"
                                },
                                model: {
                                  value: _vm.uploadedFile,
                                  callback: function($$v) {
                                    _vm.uploadedFile = $$v
                                  },
                                  expression: "uploadedFile"
                                }
                              },
                              [
                                _c("section", { staticClass: "section" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "content has-text-centered"
                                    },
                                    [
                                      _c(
                                        "p",
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "upload",
                                              size: "is-large"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("p", [
                                        _vm._v(
                                          " Drop your files here or click to upload "
                                        )
                                      ])
                                    ]
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
              ])
            ])
          ])
        : _c("div", [
            !_vm.isLoading
              ? _c("div", { staticClass: "columns is-centered" }, [
                  _c("div", { staticClass: "column is-three-quarters" }, [
                    _c("div", { staticClass: "level" }, [
                      _vm._m(1),
                      _c(
                        "div",
                        { staticClass: "level-right" },
                        [
                          _c(
                            "b-field",
                            { staticClass: "file" },
                            [
                              _c(
                                "b-upload",
                                {
                                  attrs: { accept: "application/pdf" },
                                  model: {
                                    value: _vm.uploadedFile,
                                    callback: function($$v) {
                                      _vm.uploadedFile = $$v
                                    },
                                    expression: "uploadedFile"
                                  }
                                },
                                [
                                  _c(
                                    "a",
                                    { staticClass: "button is-primary" },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "cloud-upload-outline" }
                                      }),
                                      _c("span", [_vm._v("Upload PDF")])
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ])
              : _vm._e(),
            _c("div", { staticClass: "columns is-centered " }, [
              _c("div", { staticClass: "car1d column is-three-quarters" }, [
                _c("div", { staticClass: "ca1rd-content is-half" }, [
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c(
                        "div",
                        { staticClass: "container" },
                        [
                          _c("b-input", {
                            attrs: {
                              placeholder: "Search...",
                              "icon-right": "close-circle",
                              "icon-right-clickable": "",
                              rounded: ""
                            },
                            on: {
                              "icon-right-click": function($event) {
                                _vm.searchText = ""
                              }
                            },
                            model: {
                              value: _vm.searchText,
                              callback: function($$v) {
                                _vm.searchText = $$v
                              },
                              expression: "searchText"
                            }
                          })
                        ],
                        1
                      ),
                      _c("span", { staticClass: "subtitle" }, [
                        _vm._v("Recent Documents ")
                      ]),
                      _c("br"),
                      _c("br"),
                      _c("table", { staticClass: "table is-responsive" }, [
                        _vm._m(2),
                        _c(
                          "tbody",
                          _vm._l(_vm.documents, function(document) {
                            return _c(
                              "tr",
                              {
                                key: document.id,
                                staticClass: "document-item"
                              },
                              [
                                _c(
                                  "td",
                                  {
                                    staticClass: "document-name",
                                    on: {
                                      click: function($event) {
                                        return _vm.onDocumentClick(document)
                                      }
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(document.name) + " ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.onDocumentClick(document)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "time",
                                      { attrs: { datetime: "2016-1-1" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.daysAgoTime(document.updated_at)
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.onDocumentClick(document)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "time",
                                      { attrs: { datetime: "2016-1-1" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("localTime")(
                                              document.created_at
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  [
                                    _c("document-menu", {
                                      attrs: { document: document }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]),
                      _c("b-loading", {
                        attrs: {
                          "is-full-page": false,
                          active: _vm.isSearchLoading,
                          "can-cancel": true
                        },
                        on: {
                          "update:active": function($event) {
                            _vm.isSearchLoading = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ])
          ]),
      _c("share-dialog", {
        attrs: {
          visibility: _vm.isShareDialogShown,
          "document-id": _vm.currentDocumentId
        },
        on: {
          handleDocumentShareCloseDialog: _vm.handleDocumentShareCloseDialog
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns is-centered" }, [
      _c("div", { staticClass: "column is-half" }, [
        _c("span", { staticClass: "subtitle is-4" }, [
          _vm._v(" Upload a PDF file to sign")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "level-left" }, [
      _c("div", { staticClass: "level-item" }, [
        _c("h3", { staticClass: "subtitle is-4" }, [_vm._v(" Documents ")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("th", [_vm._v(" Filename ")]),
      _c("th", [_vm._v(" Updated ")]),
      _c("th", [_vm._v(" Created ")]),
      _c("th")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }