<template>
  <div class="container">
    <b-loading
      :is-full-page="false"
      :active.sync="isLoading"
      :can-cancel="false"
    />
    <div v-if="documents.length == 0 && !isLoading && searchText == '' ">
      <section class="hero">
        <div class="hero-body">
          <div class="columns is-centered">
            <div class="column is-half">
              <span class="subtitle is-4"> Upload a PDF file to sign</span>
            </div>
          </div>
          <div class="columns is-centered ">
            <div class="column  is-half">
              <p />
              <p>
                <b-field>
                  <b-upload
                    v-model="uploadedFile"
                    drag-drop
                    expanded
                    accept="application/pdf"
                  >
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon
                            icon="upload"
                            size="is-large"
                          />
                        </p>
                        <p>
                          Drop your files here or click to upload
                        </p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-else>
      <div
        v-if="!isLoading"
        class="columns is-centered"
      >
        <div class="column is-three-quarters">
          <div class="level">
            <div class="level-left">
              <div class="level-item">
                <h3 class="subtitle is-4">
                  Documents
                </h3>
              </div>
            </div>
            <div class="level-right">
              <b-field class="file">
                <b-upload
                  v-model="uploadedFile"
                  accept="application/pdf"
                >
                  <a class="button is-primary">
                    <b-icon icon="cloud-upload-outline" />
                    <span>Upload PDF</span>
                  </a>
                </b-upload>
              </b-field>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-centered ">
        <div class="car1d column is-three-quarters">
          <div class="ca1rd-content is-half">
            <div class="content">
              <div class="container">
                <b-input
                  v-model="searchText"
                  placeholder="Search..."
                  icon-right="close-circle"
                  icon-right-clickable
                  rounded
                  @icon-right-click="searchText = ''"
                />
              </div>
              <span class="subtitle">Recent Documents </span><br><br>
              <table class="table is-responsive">
                <thead>
                  <th> Filename </th>
                  <th> Updated </th>
                  <th> Created </th>
                  <th />
                </thead>
                <tbody>
                  <tr
                    v-for="document in documents"
                    :key="document.id"
                    class="document-item"
                  >
                    <td
                      class="document-name"
                      @click="onDocumentClick(document)"
                    >
                      {{ document.name }}
                    </td>
                    <td @click="onDocumentClick(document)">
                      <time datetime="2016-1-1">{{ daysAgoTime(document.updated_at ) }}</time>
                    </td>
                    <td @click="onDocumentClick(document)">
                      <time datetime="2016-1-1">{{ document.created_at | localTime }}</time>
                    </td>
                    <td>
                      <document-menu :document="document" />
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- <b-pagination
                v-if="documentListInfo"
                :simple="true"
                :total="documentListInfo.page_count"
                :current.sync="documentListInfo.current_page"
                aria-next-label="Next"
                aria-previous-label="Previous"
                aria-page-label="Page"
                aria-current-label="Current page"
                :per-page="documents.length"
              >
                <b-pagination-button
                  slot="previous"
                  tag="button"
                >
                  Previous
                </b-pagination-button>

                <b-pagination-button
                  slot="next"
                  tag="button"
                >
                  Next
                </b-pagination-button>
              </b-pagination> -->
              <b-loading
                :is-full-page="false"
                :active.sync="isSearchLoading"
                :can-cancel="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <share-dialog
      :visibility="isShareDialogShown"
      :document-id="currentDocumentId"
      @handleDocumentShareCloseDialog="handleDocumentShareCloseDialog"
    />
  </div>
</template>

<script>
import ShareDialog from './ShareDialog';
import DocumentMenu from '@/views/documents/DocumentMenu';
import BaseUserInfo from '@/views/auth/BaseUserInfo';
import axios from 'axios';
import moment from 'moment'

import { eventHub } from "@/commons/event-bus.js";


export default {
  components: {
    ShareDialog,
    DocumentMenu,
  },
  extends: BaseUserInfo,
  data() {
    return {
      documents: [],
      isLoading: true,
      uploadedFile: null,
      isShareDialogShown: false,
      currentDocumentId: -1,
      searchText:"",
      isSearchLoading: false,
      documentListInfo: null,
      currentPage : 1
    };
  },
  watch: {
    uploadedFile(value) {
      this.uploadFile();
    },
    searchText() {
      this.getDocuments();
    }
  },

  mounted() {
    eventHub.$on("documents.list.refresh", this.getDocuments);
    this.getDocuments();
  },
  destroyed() {
    eventHub.$off("documents.list.refresh", this.getDocuments);
  },
  methods: {
    daysAgoTime(time) {
     return moment
    .utc(time)
    .local()
    .fromNow()
    },
    handleDocumentShareCloseDialog() {
      this.currentDocumentId = -1;
      this.isShareDialogShown = false;
    },
    showDocumentShareDialog(document) {
      this.currentDocumentId = document.id;
      this.isShareDialogShown = true;
    },
    onDocumentClick(document) {
      this.$router.push({
        name: 'signature_view',
        params: {
          documentId: document.id,
        },
      });
    },
    uploadFile() {
      this.isLoading = true;
      let url =
        '/organizations/' +
        this.$store.state.user.organization.id +
        '/documents';
      let formData = new FormData();
      formData.append('file', this.uploadedFile);
      axios
        .post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          this.isLoading = false;
          this.getDocuments();
          this.$buefy.toast.open('Uploaded successfully');
        })
        .catch((e) => {
          this.isLoading = false;
          this.$buefy.toast.open({
            type: 'is-danger',
            message: 'Cannot upload file.',
          });
        });
    },
    getDocuments() {
      if (this.$store.state.user == null) {
        return;
      }
      if(this.searchText != '') {
        this.isSearchLoading = true;
      }else{
        this.isLoading = true;
      }
      

      var url =
        '/organizations/' +
        this.$store.state.user.organization.id +
        '/documents';

      let _data = {'page': this.currentPage}
      if (this.searchText != null && this.searchText != '') {
        _data['search'] = this.searchText;
      }
      axios
        .get(url,{params:_data })
        .then((response) => {
          if(this.searchText != '') {
            this.isSearchLoading = false;
          }else{
            this.isLoading = false;
          }
          this.documents = response.data.results;
          this.documentListInfo = response.data;
        })
        // eslint-disable-next-line no-unused-vars
        .catch((e) => {
            if(this.searchText != '') {
              this.isSearchLoading = false;
            }else{
              this.isLoading = false;
            }
        });
    },
    userInfoUpdate() {
      this.getDocuments();
    },
  },
};
</script>

<style>
.document-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
}

.document-item {
  cursor: pointer;
}
</style>

<style lang="sass" scoped>

$grey-lighter: hsl(0, 0%, 86%) !default
$table-cell-border: 1px solid $grey-lighter !default
$table-cell-border-width: 0 0 1px !default
@media screen and (max-width: 800px)
  .is-responsive
    width: 100%
    border-collapse: collapse
    border-spacing: 0
    display: block
    position: relative

    td:empty:before
      content: '\00a0'

    th,
    td
      margin: 0
      vertical-align: top

    th
      text-align: left

    thead
      border-right: solid 2px $grey-lighter
      display: block
      float: left

      tr
        display: block
        padding: 0 10px 0 0

        th::before
          content: "\00a0"
      td,
      th
        border-width: 0 0 1px

    tbody
      display: block
      width: auto
      position: relative
      overflow-x: auto
      white-space: nowrap

      tr
        display: inline-block
        vertical-align: top

    th
      display: block
      text-align: right

    td
      display: block
      min-height: 1.25em
      text-align: left

    th,
    td
      &:last-child
        border-bottom-width: 0

    tr
      &:last-child
        td:not(:last-child)
          border: $table-cell-border
          border-width: $table-cell-border-width

    &.is-bordered
      td,
      th
        border-width: 1px
      tr
        td,
        th
          &:last-child
            border-bottom-width: 1px
        &:last-child
          td,
          th
            border-width: 1px
</style>
